import type { FunctionComponent } from "react";

import { H2, UnorderedList, UnorderedListItem } from "@homescan/ui/components/Primitives";
import { from } from "@homescan/ui/styles";
import { column } from "@homescan/ui/styles/grid";
import styled from "styled-components";

import { Section as SectionBase } from "components/Primitives";

const Section = styled(SectionBase)`
  padding-top: ${({ theme }) => theme.spacing.ss2};
  margin-bottom: ${({ theme }) => theme.spacing.ss4} !important;

  ${from("desktop")} {
    margin-bottom: ${({ theme }) => theme.spacing.ss7} !important;
  }
`;

const CardsGrid = styled(UnorderedList)``;

const CardOnGrid = styled(UnorderedListItem)`
  min-height: 200px;

  ${from("desktop")} {
    ${column(6)}
  }
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ss3};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: center;

  ${H2} {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const Separator = styled.span`
  width: 60%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary.default};
`;

const CardHeader = styled(H2)`
  font-size: ${({ theme }) => theme.fontSize.fs4};
  text-align: center;

  ${from("tablet")} {
    font-size: ${({ theme }) => theme.fontSize.fs5};
  }
`;

export const Numbers: FunctionComponent = () => (
  <>
    <Section>
      <CardsGrid>
        <CardOnGrid>
          <CardContent>
            <CardHeader>10 milionów</CardHeader>
            <Separator />
            Ogłoszeń nieruchomości w Homescan
          </CardContent>
        </CardOnGrid>
        <CardOnGrid>
          <CardContent>
            <CardHeader>640 milionów</CardHeader>
            <Separator />
            Historycznych cen nieruchomości
          </CardContent>
        </CardOnGrid>
        <CardOnGrid>
          <CardContent>
            <CardHeader>500 tysięcy</CardHeader>
            <Separator />
            Nowych ogłoszeń nieruchomości miesięcznie
          </CardContent>
        </CardOnGrid>
        <CardOnGrid>
          <CardContent>
            <CardHeader>1 milion</CardHeader>
            <Separator />
            Codziennie aktualizowanych ogłoszeń
          </CardContent>
        </CardOnGrid>
      </CardsGrid>
    </Section>
  </>
);
