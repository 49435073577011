import { type FunctionComponent } from "react";

import { PriceHistory, type PriceHistoryData } from "@homescan/ui/components/PriceHistory";
import { Highlight } from "@homescan/ui/components/Primitives";
import { from } from "@homescan/ui/styles";
import styled from "styled-components";

import { HorizontalFeatureSection } from "./HorizontalFeatureSection";

const DUMMY_TIME_LINE_DATA: PriceHistoryData[] = [
  {
    price: 666000.0,
    fluctuation: 0.0,
    position: 19,
    createdAt: "2022-12-09T00:16:16.383Z",
    updatedAt: "2022-12-09T00:16:16.383Z",
    duration: "103 dni temu",
    text: "OFFER.ADDED",
    icon: "Plus",
  },
  {
    price: 676000.0,
    fluctuation: 10000,
    position: 28,
    createdAt: "2023-03-02T01:18:24.306Z",
    updatedAt: "2023-03-02T01:18:24.306Z",
    duration: "20 dni temu",
    text: "OFFER.FLUCTUATION",
    icon: "BarChart2",
  },
  {
    price: 646000.0,
    fluctuation: -30000,
    position: 28,
    createdAt: "2023-03-07T01:18:24.306Z",
    updatedAt: "2023-03-0T01:18:24.306Z",
    duration: "15 dni temu",
    text: "OFFER.FLUCTUATION",
    icon: "BarChart2",
  },
  {
    price: 646000.0,
    fluctuation: 0.0,
    position: 18,
    createdAt: "2023-03-21T01:05:07.774Z",
    updatedAt: "2023-03-21T01:05:07.774Z",
    duration: "16 godzin temu",
    text: "OFFER.CURRENT",
    icon: "Rss",
  },
];

const PriceHistoryWrapper = styled.div`
  width: 100%;
  scale: 0.8;

  ${from("desktop")} {
    scale: 1;
  }
`;

export const HistoryFeature: FunctionComponent = () => (
  <HorizontalFeatureSection
    title="Historia zmian ceny ogłoszenia"
    icon="Activity"
    description={
      <>
        Oglądając ogłoszenia nieruchomości widzisz jedynie ich aktualną cenę. Czy nie byłoby
        wspaniale mieć dostęp do pełnej historii, jak zmieniała się ona w przeszłości? Z Homescan
        masz to na wyciągnięcie ręki! Wszystkie ogłoszenia w naszym systemie są conajmniej raz
        dziennie analizowane, a ich cena aktualizowana. Ta informacja będzie nieoceniona podczas
        rozmów z właścicielem lub pośrednikiem oraz{" "}
        <Highlight>poprawi Twoją pozycję negocjacyjną</Highlight>.
      </>
    }
  >
    <PriceHistoryWrapper>
      <PriceHistory area1={41.71} data={DUMMY_TIME_LINE_DATA} />
    </PriceHistoryWrapper>
  </HorizontalFeatureSection>
);
