import styled from "styled-components";

import { DateTime } from "../../utils/date";
import { BadgeGroup, SmallBadge, SmallSuccessBadge } from "../Badge";
import { H3 as H3Base } from "../Primitives";
import type { TimelineConfig } from "../Timeline";
import { Tooltip } from "../Tooltip";
import type { PriceHistoryData } from "./types";

const timelineText = (code: string) => {
  switch (code) {
    case "OFFER.ADDED":
      return "Oferta dodana";
    case "OFFER.RESTORED":
      return "Oferta wznowiona";
    case "OFFER.FLUCTUATION":
      return "Zmiana ceny";
    case "OFFER.EXPIRED":
      return "Oferta wygasła";
    case "OFFER.CURRENT":
      return "Ostatnia aktualizacja";
    default:
      return "";
  }
};

/**
 * @description Offer price formatter
 * @param value price
 * @returns formatted price
 */
export const formatPrice = (value: number | string) => {
  const price = typeof value === "string" ? Number(value) : value;
  return new Intl.NumberFormat("pl", { style: "currency", currency: "PLN" }).format(price);
};

export const TimelineBadge = styled(SmallBadge)`
  padding: ${({ theme }) => `${theme.spacing.ss2} ${theme.spacing.ss0}`};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const H3 = styled(H3Base)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const getConfig = ({ area1 }: { area1: number }): TimelineConfig<PriceHistoryData> => ({
  left: ({ createdAt, duration }) => (
    <Tooltip content={DateTime.fromISO(createdAt).format(DateTime.DATE_FORMAT.default)}>
      {duration}
    </Tooltip>
  ),
  right: ({ text, price, fluctuation }) => (
    <>
      <H3>{timelineText(text)}</H3>
      <BadgeGroup>
        <TimelineBadge>{formatPrice(Number(price))}</TimelineBadge>
        <TimelineBadge>{formatPrice(Number(price) / area1)}/&#13217;</TimelineBadge>
      </BadgeGroup>
      {Number(fluctuation) < 0 && (
        <SmallSuccessBadge>{formatPrice(Number(fluctuation))}</SmallSuccessBadge>
      )}
      {Number(fluctuation) > 0 && <SmallBadge>{formatPrice(Number(fluctuation))}</SmallBadge>}
    </>
  ),
});
