import { type FunctionComponent, type ReactNode, useRef } from "react";

import { Icon, IconName } from "@homescan/ui/components/Icon";
import { H2, Message } from "@homescan/ui/components/Primitives";
import { from } from "@homescan/ui/styles";
import { motion, useScroll, useTransform } from "framer-motion";
import styled from "styled-components";

const HorizontalFeatureSectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.ss2};
  height: min-content;
  margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss2} ${theme.spacing.ss10}`};

  ${from("tablet")} {
    flex-direction: row;
    align-items: flex-start;
    margin: ${({ theme }) => `${theme.spacing.ss9} ${theme.spacing.ss2} ${theme.spacing.ss10}`};
  }
`;

const HorizontalFeatureSectionPreviewWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex: 1;
  will-change: transform, opacity;
`;

const HorizontalFeatureSectionDetails = styled.div`
  position: sticky;
  top: calc(50vh - 120px);
  right: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.ss2};
  align-items: center;

  ${H2} {
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: ${({ theme }) => theme.fontSize.fs4};
  }

  ${Message} {
    width: 80%;
  }
`;

const Separator = styled.span`
  width: 60%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary.default};
`;

type HorizontalFeatureSectionProps = {
  children: ReactNode;
  title: string;
  icon: IconName;
  description: ReactNode;
};

export const HorizontalFeatureSection: FunctionComponent<HorizontalFeatureSectionProps> = ({
  children,
  icon,
  title,
  description,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end end"],
  });

  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const y = useTransform(scrollYProgress, [0, 1], [0, 0]);

  return (
    <HorizontalFeatureSectionWrapper ref={containerRef}>
      <HorizontalFeatureSectionPreviewWrapper style={{ y, opacity }}>
        {children}
      </HorizontalFeatureSectionPreviewWrapper>
      <HorizontalFeatureSectionDetails>
        <Icon size={48} name={icon} />
        <H2>{title}</H2>
        <Separator />
        <Message>{description}</Message>
      </HorizontalFeatureSectionDetails>
    </HorizontalFeatureSectionWrapper>
  );
};
