import type { FunctionComponent } from "react";

import { TertiaryButton } from "@homescan/ui/components/Button";
import { Icon } from "@homescan/ui/components/Icon";
import { H4, Highlight, Message } from "@homescan/ui/components/Primitives";
import styled from "styled-components";

import { HorizontalFeatureSection } from "./HorizontalFeatureSection";

const DUMMY_FAV_LISTS_DATA = [
  {
    attributes: {
      createdAt: "2023-01-13T23:04:49.435Z",
      id: "dummy_list_id1",
      name: "Mieszkania Wrocław",
      offers: 20,
      updatedAt: "2023-03-12T18:28:18.121Z",
    },
    type: "favoriteListWithOffers",
    id: "dummy_list_id1",
  },
  {
    attributes: {
      createdAt: "2023-01-13T23:04:49.435Z",
      id: "dummy_list_id2",
      name: "Działki Karpacz",
      offers: 12,
      updatedAt: "2023-03-12T18:28:18.121Z",
    },
    type: "favoriteListWithOffers",
    id: "dummy_list_id2",
  },
  {
    attributes: {
      createdAt: "2023-01-13T23:04:49.435Z",
      id: "dummy_list_id3",
      name: "Kawalerki Kraków",
      offers: 7,
      updatedAt: "2023-03-12T18:28:18.121Z",
    },
    type: "favoriteListWithOffers",
    id: "dummy_list_id3",
  },
  {
    attributes: {
      createdAt: "2023-01-13T23:04:49.435Z",
      id: "dummy_list_id4",
      name: "Domy Warszawa",
      offers: 23,
      updatedAt: "2023-03-12T18:28:18.121Z",
    },
    type: "favoriteListWithOffers",
    id: "dummy_list_id4",
  },
];

const DashboardTiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ss4};
`;

const DashboardTileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 340px;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: ${({ theme }) => theme.radius.rad4};
  border: 1px solid ${({ theme }) => theme.colors.misc.border.light};
`;

const DashboardTileContent = styled.div`
  display: flex;
  /* Needed to properly work with truncated texts */
  min-width: 0;
  padding: ${({ theme }) =>
    `${theme.spacing.ss3} ${theme.spacing.ss0} ${theme.spacing.ss3} ${theme.spacing.ss3}`};
  gap: ${({ theme }) => theme.spacing.ss2};
`;

const DashboardTileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DashboardTileIcon = styled(Icon).attrs({
  size: 32,
})``;

const DashboardTileInformationWrapper = styled.div`
  min-width: 0;

  ${H4} {
    margin-bottom: ${({ theme }) => theme.spacing.ss1};
  }

  ${Message} {
    width: max-content;
    font-size: ${({ theme }) => theme.fontSize.fs2};
  }
`;

const DashboardTileActionsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.ss2};
`;

const DashboardTileActionButton = styled(TertiaryButton)`
  height: 100%;
  width: ${({ theme }) => theme.spacing.ss7};
`;

export const FavListFeature: FunctionComponent = () => (
  <HorizontalFeatureSection
    icon="Heart"
    title="Twoje listy ulubionych ogłoszeń"
    description={
      <>
        Dzięki listom ulubionych <Highlight>pogrupujesz ogłoszenia</Highlight>, które Cię
        zainteresowały, aby mieć do nich szybki dostęp w przyszłości. Wszystkie ogłoszenia dodane do
        list ulubionych będą monitorowane i otrzymasz <Highlight>powiadomienia e-mail</Highlight>{" "}
        jeśli wygasną, zostaną wznowione lub zmieni się ich cena.
      </>
    }
  >
    <DashboardTiles>
      {DUMMY_FAV_LISTS_DATA.map(({ attributes }) => (
        <DashboardTileWrapper key={attributes.id}>
          <DashboardTileContent>
            <DashboardTileIconWrapper>
              <DashboardTileIcon name="Heart" />
            </DashboardTileIconWrapper>
            <DashboardTileInformationWrapper>
              <H4 isTruncated>{attributes.name}</H4>
              <Message>Liczba ofert: {attributes.offers}</Message>
            </DashboardTileInformationWrapper>
          </DashboardTileContent>
          <DashboardTileActionsWrapper>
            <DashboardTileActionButton>
              <Icon name="Trash2" title="Usuń zapisane wyszukiwanie" size={24} />
            </DashboardTileActionButton>
          </DashboardTileActionsWrapper>
        </DashboardTileWrapper>
      ))}
    </DashboardTiles>
  </HorizontalFeatureSection>
);
