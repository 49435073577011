import type { FunctionComponent, ReactNode } from "react";

import { AnimatePresence, type AnimationProps, motion, type Variants } from "framer-motion";
import { type Placement as PlacementBase, useHover, useLayer } from "react-laag";
import styled from "styled-components";

const animationVariants: Variants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
};

const TooltipAnimationProps: AnimationProps = {
  variants: animationVariants,
  initial: "initial",
  animate: "animate",
  exit: "initial",
  transition: { duration: 0.1 },
};

const TooltipBox = styled(motion.div).attrs(TooltipAnimationProps)`
  background-color: ${({ theme }) => theme.colors.background.quinary};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.fontSize.fs2};
  padding: ${({ theme }) => `${theme.spacing.ss1} ${theme.spacing.ss3}`};
  border-radius: ${({ theme }) => theme.radius.rad1};
  box-shadow: ${({ theme }) => theme.boxShadows.small};
  z-index: ${({ theme }) => theme.zIndex.tooltip};
  min-width: min-content;
  will-change: scale, opacity;
`;

export type Placement = PlacementBase;

type TooltipProps = {
  className?: string;
  visible?: boolean;
  placement?: Placement;
  delay?: number;
  children: ReactNode;
  content: ReactNode;
};

export const Tooltip: FunctionComponent<TooltipProps> = ({
  className,
  children,
  content,
  placement = "top-center",
  visible = true,
  delay = 1000,
}) => {
  const [isOver, hoverProps] = useHover({ delayEnter: delay, delayLeave: 100 });
  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: visible && isOver,
    placement,
    triggerOffset: 8,
    onParentClose: hoverProps.onMouseLeave,
  });

  return (
    <>
      <span className={className} {...triggerProps} {...hoverProps}>
        {children}
      </span>
      {visible &&
        renderLayer(
          <AnimatePresence>
            {isOver && <TooltipBox {...layerProps}>{content}</TooltipBox>}
          </AnimatePresence>,
        )}
    </>
  );
};
