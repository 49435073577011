import type { FunctionComponent } from "react";

import { Icon } from "@homescan/ui/components/Icon";
import { Image } from "@homescan/ui/components/Image";
import { ExternalLink, Link, PrimaryLink } from "@homescan/ui/components/Link";
import { H1, H4 } from "@homescan/ui/components/Primitives";
import { from, upTo } from "@homescan/ui/styles";
import styled from "styled-components";

import { Section as SectionBase, SectionBaseComponent } from "components/Primitives";
import Logo from "public/logo.svg";
import { routes } from "utils/routes";

const Section = styled(SectionBase)`
  padding-top: ${({ theme }) => theme.spacing.ss0};

  ${SectionBaseComponent.SectionHeading} {
    margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss2}`};

    ${from("tablet")} {
      margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss4}`};
    }

    ${from("desktop")} {
      margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss7}`};
    }

    ${from("desktopFullHD")} {
      margin: ${({ theme }) => theme.spacing.ss0};
    }
  }

  ${SectionBaseComponent.SectionContent} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.ss3};
  }
`;

const Heading = styled(H1)`
  text-align: center;
  line-height: 45px;
  letter-spacing: 1px;
  background: ${({ theme }) =>
    `linear-gradient(to right bottom, ${theme.colors.text.primary}, ${theme.colors.text.secondary})`};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(#3b3b3b 0px 4px 8px);

  br {
    ${upTo("tablet")} {
      display: none;
    }
  }
`;

const LogoImage = styled(Image)`
  width: 120px;
  height: 120px;

  ${from("desktop")} {
    width: 180px;
    height: 180px;
  }
`;

const Separator = styled.span`
  width: 320px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.misc.border.normal};
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.ss2};
`;

const AppLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.fs2};
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.ss4};
  gap: ${({ theme }) => theme.spacing.ss2};

  ${H4} {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.ss4};
  justify-content: center;
`;

const SocialIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const CallToAction: FunctionComponent = () => (
  <Section>
    <LogoImage alt="logo" src={Logo} width={240} height={240} />
    <Heading>
      Stworzony by pomóc w znalezieniu
      <br /> twojej następnej nieruchomości
    </Heading>
    <Separator />
    <ActionsWrapper>
      <PrimaryLink href={routes.register} title="Utwórz darmowe konto" />
      <AppLink href={routes.app} title="Przejdź do aplikacji" />
    </ActionsWrapper>
    <SocialLinksWrapper>
      <H4>Znajdziesz nas na</H4>
      <SocialLinks>
        <ExternalLink href="https://www.facebook.com/homescanpl">
          <SocialIcon name="Facebook" size={40} />
        </ExternalLink>
        <ExternalLink href="https://twitter.com/homescanpl">
          <SocialIcon name="Twitter" size={40} />
        </ExternalLink>
      </SocialLinks>
    </SocialLinksWrapper>
  </Section>
);
