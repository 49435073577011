import type { ReactElement } from "react";

import styled from "styled-components";

import { upTo } from "../../styles";
import type { BaseData } from "../../utils/utilityTypes";
import { BadgeGroup } from "../Badge";
import { BadgeWrapper } from "../Badge/Badge";
import { Icon } from "../Icon";
import type { TimelineConfig, TimelineDataBasic } from "./types";

const TimelineWrapper = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  list-style: none;
`;

const TimelineItem = styled.li`
  display: flex;
  position: relative;
  min-height: 70px;
`;

const TimelineItemLeftContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: ${({ theme }) =>
    `${theme.spacing.ss4} ${theme.spacing.ss3} ${theme.spacing.ss1} ${theme.spacing.ss0}`};
`;

const TimelineItemRightContent = styled(TimelineItemLeftContent)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${({ theme }) =>
    `${theme.spacing.ss3} ${theme.spacing.ss0} ${theme.spacing.ss1} ${theme.spacing.ss3}`};

  ${upTo("mobile")} {
    ${BadgeGroup} {
      ${BadgeWrapper} {
        margin-bottom: ${({ theme }) => theme.spacing.ss0};
        margin-right: ${({ theme }) => theme.spacing.ss0};
      }
    }
  }
`;

const TimelineSeparator = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  align-items: center;
`;

const TimelineDot = styled.span`
  display: flex;
  padding: ${({ theme }) => theme.spacing.ss2};
  align-self: baseline;
  box-shadow: ${({ theme }) => theme.boxShadows.small};
  margin: ${({ theme }) => `${theme.spacing.ss2} ${theme.spacing.ss0}`};
  border-radius: ${({ theme }) => theme.radius.rad4};
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.misc.border.normal};
`;

const TimelineConnector = styled.span`
  flex-grow: 1;
  min-height: 24px;
  width: 2px;
  background-color: ${({ theme }) => theme.colors.misc.border.normal};
`;

type TimelineProps<Data> = {
  data: Array<Data & TimelineDataBasic>;
  config: TimelineConfig<Data>;
};

export const Timeline = <Data extends BaseData>({
  data,
  config,
}: TimelineProps<Data>): ReactElement => (
  <TimelineWrapper>
    {data.map((item, idx) => (
      <TimelineItem key={idx}>
        <TimelineItemLeftContent>{config.left(item)}</TimelineItemLeftContent>
        <TimelineSeparator>
          <TimelineDot>
            <Icon name={item.icon} />
          </TimelineDot>
          {idx < data.length - 1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineItemRightContent>{config.right(item)}</TimelineItemRightContent>
      </TimelineItem>
    ))}
  </TimelineWrapper>
);
