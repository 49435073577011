import type { FunctionComponent } from "react";

import { Image } from "@homescan/ui/components/Image";
import { Highlight } from "@homescan/ui/components/Primitives";
import { from } from "@homescan/ui/styles";
import styled from "styled-components";

import { Section as SectionBase, SectionBaseComponent } from "components/Primitives";
import Planet from "public/planet.webp";

import { FavListFeature } from "./components/FavListFeature";
import { FeaturesSection, type FeaturesSectionConfig } from "./components/FeaturesSection";
import { HistoryFeature } from "./components/HistoryFeature";
import { MapSearchFeatureSection } from "./components/MapSearchFeatureSection";

type SectionStyleProps = {
  noBottomMargin?: boolean;
};

const Section = styled(SectionBase)<SectionStyleProps>`
  padding-top: ${({ theme }) => theme.spacing.ss0};
  margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss0} ${theme.spacing.ss6}`};
  gap: ${({ theme }) => theme.spacing.ss0};

  ${({ theme, noBottomMargin }) =>
    noBottomMargin &&
    `
    margin-bottom: ${theme.spacing.ss0};
  `}

  ${SectionBaseComponent.SectionHeading} {
    margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss2}`};
    line-height: 45px;

    ${from("tablet")} {
      margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss4}`};
    }

    ${from("desktop")} {
      margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss7}`};
    }

    ${from("desktopFullHD")} {
      margin: ${({ theme }) => theme.spacing.ss0};
    }
  }
`;

const Separator = styled.span`
  width: 60%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary.default};
`;

const TOP_FEATURES_CONFIG: FeaturesSectionConfig = {
  leftColumn: [
    {
      icon: "Search",
      title: "Tylko ogłoszenia spełniające Twoje kryteria",
      description: (
        <>
          Homescan oferuje <Highlight>zaawansowane filtrowanie i sortowanie</Highlight> dzięki
          którym będziesz w stanie określić dokładnie swoje kryteria wyszukiwania. Stworzone przez
          siebie filtry możesz nazwać i zapisać, aby wrócić do nich w przyszłości. Ponadto dzięki
          zapisanym filtrom otrzymasz <Highlight>powiadomienia e-mail</Highlight> o wszystkich
          nowych ogłoszeniach spełniających Twoje kryteria.
        </>
      ),
    },
  ],
  rightColumn: [
    {
      icon: "Bell",
      title: "Powiadomienia o nowych ogłoszeniach",
      description: (
        <>
          Spędzasz dużo czasu przeszukując portale nieruchomości? Dzięki Homescan stanie się to
          zbędne! W kilku prostych krokach ustawisz interesujące Cię filtry i od tej pory wszystkie
          spełniające kryteria ogłoszenia ze wszystkich portali znajdziesz w jednym miejscu, a
          dodatkowo otrzymasz{" "}
          <Highlight>natychmiastowe powiadomienia e-mail o nowo dodanych ofertach</Highlight>.
        </>
      ),
    },
  ],
};

const BOTTOM_FEATURES_CONFIG: FeaturesSectionConfig = {
  leftColumn: [
    {
      icon: "BarChart2",
      title: "Porównanie cen ogłoszeń w okolicy",
      description: (
        <>
          Homescan posiada dane <Highlight>milionów ogłoszeń nieruchomości</Highlight>. Dzięki
          takiemu zbiorowi danych jesteśmy w stanie trafnie oceniać czy dana oferta znajduje się
          wśród najtańszych czy najdroższych w okolicy.
        </>
      ),
    },
    {
      icon: "Archive",
      title: "Archiwum ogłoszeń",
      description: (
        <>
          Czy zdarzyło się, że byłeś zainteresowany ofertą, która nagle zniknęła z portalu
          ogłoszeń? Posiadając dostęp do Homescan to nic straconego, wystarczy że przeszukasz
          nasze <Highlight>archiwum zawierające miliony ogłoszeń z całej Polski</Highlight> i
          znajdziesz wszystkie informacje o interesującej Cię ofercie!
        </>
      ),
    },
  ],
  rightColumn: [
    {
      icon: "Type",
      title: "Zaawansowane wyszukiwanie po treści ogłoszenia",
      description: (
        <>
          Czasami istotne jest to co ogłoszeniodawca zawarł w treści ogłoszenia, czy też w jego
          tytule lub dodatkowych informacjach. Korzystając z aplikacji Homescan będziesz w stanie{" "}
          <Highlight>błyskawicznie przeszukać miliony ogłoszeń</Highlight> pod kątem zawarcia czy
          wykluczenia konkretnych fraz jak np. do&nbsp;remontu, blisko&nbsp;uczelni lub
          pilna&nbsp;sprzedaż. Nasz silnik wyszukiwania umożliwia budowanie rozbudowanych zapytań,
          przykładowo fraza <Highlight>&quot;do&nbsp;*remon*&quot;</Highlight> zwróci ogłoszenia
          zawierające: mieszkanie <Highlight>do&nbsp;remon</Highlight>tu, <Highlight>do</Highlight>
          &nbsp;generalnego&nbsp;<Highlight>remon</Highlight>tu, <Highlight>do</Highlight>&nbsp;wy
          <Highlight>remon</Highlight>towania itd.
        </>
      ),
    },
  ],
};

export const Features: FunctionComponent = () => (
  <>
    <Section
      title="Wszystkie oferty w jednym miejscu"
      separator={<Separator />}
      subTitle={
        <>
          Wyszukaj oferty nieruchomości ze wszystkich największych portali w Polsce.
          <br /> Dzięki rozbudowanym filtrom jeszcze dokładniej zawęzisz listę do własnych
          kryteriów.
          <br /> Homescan pozwala zapisać wybrane filtry oraz dowolnie tworzyć listy ulubionych
          ogłoszeń.
          <br /> Dzięki obu tym funkcjonalnościom będziemy automatycznie wyszukiwać interesujące Cię
          oferty, a&nbsp;także powiadamiać Cię o nowych ogłoszeniach oraz ich zmianach.
        </>
      }
    >
      <FeaturesSection config={TOP_FEATURES_CONFIG} />
      <HistoryFeature />
      <FavListFeature />
      <MapSearchFeatureSection />
      <FeaturesSection isCentered config={BOTTOM_FEATURES_CONFIG} />
    </Section>
    <Section
      noBottomMargin
      title="Nieustannie analizujemy dla Ciebie wszystkie największe portale ogłoszeniowe w Polsce."
      separator={<Separator />}
      subTitle={
        <>
          Homescan automatycznie pobiera dane z największych portali ogłoszeniowych, szuka nowych
          ogłoszeń oraz sprawdza ceny i treść. Wszystko po to, abyś mógł znaleźć właściwą
          nieruchomość nie tracąc swojego cennego czasu. Teraz z Homescan to niezwykle proste,
          sprawdź sam!
        </>
      }
    >
      <Image
        priority
        quality={100}
        alt="Planet"
        src={Planet}
        width={1512}
        height={427}
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
    </Section>
  </>
);
