import type { FunctionComponent, ReactNode } from "react";

import { Icon, type IconName } from "@homescan/ui/components/Icon";
import { H2, Message, UnorderedList, UnorderedListItem } from "@homescan/ui/components/Primitives";
import { from } from "@homescan/ui/styles";
import { column } from "@homescan/ui/styles/grid";
import styled from "styled-components";

const FeaturesSectionWrapper = styled(UnorderedList)`
  background-color: #242424;
  padding: ${({ theme }) => `${theme.spacing.ss4} ${theme.spacing.ss3}`};
  margin-top: ${({ theme }) => theme.spacing.ss6};
  border-top: 1px solid ${({ theme }) => theme.colors.misc.border.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.misc.border.light};

  ${from("desktop")} {
    padding: ${({ theme }) => `${theme.spacing.ss8} ${theme.spacing.ss6}`};
  }

  ${from("desktopFullHD")} {
    border: 1px solid ${({ theme }) => theme.colors.misc.border.light};
    border-radius: ${({ theme }) => theme.radius.rad2};
  }
`;

type FeaturesSectionColumnStyleProps = {
  isCentered: boolean;
};

const FeaturesSectionColumn = styled(UnorderedListItem)<FeaturesSectionColumnStyleProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ss9};

  ${({ isCentered }) =>
    isCentered &&
    `  
  justify-content: center;
  align-items: center;
  `}

  ${from("desktop")} {
    ${column(6)}
  }
`;

const FeatureWrapper = styled.div``;

const FeatureContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ss3};

  ${from("desktop")} {
    padding: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss4}`};
  }

  ${H2} {
    color: ${({ theme }) => theme.colors.text.primary};
    text-align: center;

    ${from("tablet")} {
      font-size: ${({ theme }) => theme.fontSize.fs4};
    }
  }

  ${Message} {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const Separator = styled.span`
  width: 60%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary.default};
`;

export type FeatureData = {
  icon: IconName;
  title: string;
  description: ReactNode;
};

export type FeaturesSectionConfig = {
  leftColumn: FeatureData[];
  rightColumn: FeatureData[];
};

type FeaturesSectionProps = {
  config: FeaturesSectionConfig;
  isCentered?: boolean;
};

export const FeaturesSection: FunctionComponent<FeaturesSectionProps> = ({
  isCentered = false,
  config,
}) => (
  <FeaturesSectionWrapper>
    <FeaturesSectionColumn isCentered={isCentered}>
      {config.leftColumn.map(({ title, icon, description }) => (
        <FeatureWrapper key={`${title}_left_column_feature_key`}>
          <FeatureContent>
            <Icon size={48} name={icon} />
            <Separator />
            <H2>{title}</H2>
            <Message>{description}</Message>
          </FeatureContent>
        </FeatureWrapper>
      ))}
    </FeaturesSectionColumn>
    <FeaturesSectionColumn isCentered={isCentered}>
      {config.rightColumn.map(({ title, icon, description }) => (
        <FeatureWrapper key={`${title}_right_column_feature_key`}>
          <FeatureContent>
            <Icon size={48} name={icon} />
            <Separator />
            <H2>{title}</H2>
            <Message>{description}</Message>
          </FeatureContent>
        </FeatureWrapper>
      ))}
    </FeaturesSectionColumn>
  </FeaturesSectionWrapper>
);
