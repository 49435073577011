import { type FunctionComponent, useMemo } from "react";

import { Timeline } from "../Timeline";
import type { PriceHistoryData } from "./types";
import { getConfig } from "./utils";

type PriceHistoryProps = {
  area1: number | null;
  data: PriceHistoryData[];
};

export const PriceHistory: FunctionComponent<PriceHistoryProps> = ({ area1, data }) => {
  const timelineConfig = useMemo(() => getConfig({ area1: Number(area1) }), [area1]);

  return <Timeline<PriceHistoryData> config={timelineConfig} data={data} />;
};
