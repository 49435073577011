import type { FunctionComponent, ReactNode } from "react";

import styled, { css } from "styled-components";

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.tertiary.default};
  padding: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss3}`};
  border-radius: ${({ theme }) => theme.radius.rad1};
  color: ${({ theme }) => theme.colors.text.primary};
  white-space: nowrap;
`;

type BadgeProps = {
  className?: string;
  children: ReactNode;
};

export const Badge: FunctionComponent<BadgeProps> = ({ className, children }) => (
  <BadgeWrapper className={className}>{children}</BadgeWrapper>
);

const SmallBadgeCommonCSS = css`
  height: 25px;
  padding: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss2}`};
  font-size: ${({ theme }) => theme.fontSize.fs3};
`;

export const SmallBadge = styled(Badge)`
  ${SmallBadgeCommonCSS}
`;

export const ErrorBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.error.default};
`;

export const SmallErrorBadge = styled(ErrorBadge)`
  ${SmallBadgeCommonCSS}
`;

export const SuccessBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.text.primary};
  background-color: ${({ theme }) => theme.colors.primary.default};
`;

export const SmallSuccessBadge = styled(SuccessBadge)`
  ${SmallBadgeCommonCSS}
`;
