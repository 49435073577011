import type { FunctionComponent, ReactNode } from "react";

import { H1, H3 } from "@homescan/ui/components/Primitives";
import { from, upTo } from "@homescan/ui/styles";
import styled from "styled-components";

const SectionWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ss7};
  padding-top: ${({ theme }) => theme.spacing.ss4};
  margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss2}`};

  ${from("tablet")} {
    margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss4}`};
  }

  ${from("desktop")} {
    margin: ${({ theme }) => `${theme.spacing.ss0} ${theme.spacing.ss7}`};
  }

  ${from("desktopFullHD")} {
    margin: ${({ theme }) => theme.spacing.ss0};
  }
`;

const SectionHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: ${({ theme }) => theme.spacing.ss4};
  max-width: 800px;
`;

const SectionTitle = styled(H1)`
  color: ${({ theme }) => theme.colors.text.primary} !important;
  text-align: center;
`;

const SectionSubTitle = styled(H3)`
  text-align: center;
  line-height: 25px;

  br {
    ${upTo("mobile")} {
      display: none;
    }
  }
`;

const SectionContent = styled.div``;

export const SectionBaseComponent = {
  SectionHeading,
  SectionContent,
};

type SectionProps = {
  className?: string;
  title?: ReactNode;
  separator?: ReactNode;
  subTitle?: ReactNode;
  children: ReactNode;
};

export const Section: FunctionComponent<SectionProps> = ({
  className,
  children,
  title,
  subTitle,
  separator = null,
}) => (
  <SectionWrapper className={className}>
    {title && (
      <SectionHeading>
        <SectionTitle>{title}</SectionTitle>
        {separator}
        {subTitle && <SectionSubTitle>{subTitle}</SectionSubTitle>}
      </SectionHeading>
    )}
    <SectionContent>{children}</SectionContent>
  </SectionWrapper>
);
