import { PageMeta } from "@homescan/ui/components/PageMeta";
import { NextPage } from "next";

import { CallToAction } from "modules/CallToAction";
import { Features } from "modules/Features";
import { Hero } from "modules/Hero";
import { Numbers } from "modules/Numbers";

const Home: NextPage = () => (
  <>
    <PageMeta subTitle="Znajdź okazję na rynku nieruchomości" />
    <Hero />
    <Features />
    <Numbers />
    <CallToAction />
  </>
);

export default Home;
