import { type FunctionComponent, useRef } from "react";

import { Icon } from "@homescan/ui/components/Icon";
import { Image } from "@homescan/ui/components/Image";
import { H2, Highlight, Message } from "@homescan/ui/components/Primitives";
import { from } from "@homescan/ui/styles";
import { motion, useScroll, useTransform } from "framer-motion";
import styled from "styled-components";

import mapDemoImage from "public/features/mapDemo.webp";

const HorizontalFeatureSectionWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: min-content;
  margin: ${({ theme }) => `${theme.spacing.ss10} ${theme.spacing.ss0}`};
  will-change: transform;
`;

const HorizontalFeatureSectionPreviewWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex: 1;
  will-change: opacity;
`;

const HorizontalFeatureSectionDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.ss2};
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.onTopOfInitial};

  ${H2} {
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: ${({ theme }) => theme.fontSize.fs4};
  }

  ${Message} {
    width: 60%;
  }
`;

const Separator = styled.span`
  width: 60%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary.default};
`;

const CardImageWrapper = styled.div`
  position: relative;
  top: 56px;
  width: 70%;
  height: 280px;
  border-radius: ${({ theme }) => theme.radius.rad1};
  border: 1px solid ${({ theme }) => theme.colors.misc.border.light};
  overflow: hidden;

  ${from("desktop")} {
    max-height: 300px;
    width: 50%;
    border-radius: ${({ theme }) => theme.radius.rad2};
  }

  ${from("desktopFullHD")} {
    max-height: 360px;
    width: 50%;
    border-radius: ${({ theme }) => theme.radius.rad2};
  }
`;

const CardImage = styled(Image)`
  object-fit: cover;
`;

const MapSearchImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.misc.bottomOverlay};
  opacity: 0.8;
`;

export const MapSearchFeatureSection: FunctionComponent = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end end"],
  });

  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const y = useTransform(scrollYProgress, [0, 1], [-80, 0]);

  return (
    <HorizontalFeatureSectionWrapper ref={containerRef} style={{ y }}>
      <HorizontalFeatureSectionPreviewWrapper style={{ opacity }}>
        <CardImageWrapper>
          <CardImage alt="Wyszukiwanie na mapie" quality={100} src={mapDemoImage} fill />
          <MapSearchImageOverlay />
        </CardImageWrapper>
      </HorizontalFeatureSectionPreviewWrapper>
      <HorizontalFeatureSectionDetails>
        <Icon size={48} name="Map" />
        <H2>Wyszukiwanie na mapie</H2>
        <Separator />
        <Message>
          Jeśli poszukujesz nieruchomości w konkretnej lokalizacji skorzystaj z możliwości
          wyszukiwania ogłoszeń <Highlight>definiując dowolny obszar na mapie</Highlight>. Po
          zapisaniu swojego filtra otrzymasz powiadomienia e-mail o wszystkich nowych ofertach w
          zdefiniowenym przez Ciebie obszarze.
        </Message>
      </HorizontalFeatureSectionDetails>
    </HorizontalFeatureSectionWrapper>
  );
};
