import { type FunctionComponent, useRef } from "react";

import { Image } from "@homescan/ui/components/Image";
import { Link, PrimaryLink } from "@homescan/ui/components/Link";
import {
  H1 as H1Base,
  H2 as H2Base,
  Section as SectionBase,
} from "@homescan/ui/components/Primitives";
import { from, upTo } from "@homescan/ui/styles";
import { type AnimationProps, motion, useScroll, useTransform } from "framer-motion";
import styled from "styled-components";

import { Logo as LogoBase } from "components/TopMenu";
import demoScreen from "public/demo.webp";
import { routes } from "utils/routes";

const HeroSection = styled(SectionBase)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.ss4};
  margin-bottom: ${({ theme }) => theme.spacing.ss4} !important;

  ${from("desktop")} {
    margin-bottom: ${({ theme }) => theme.spacing.ss7} !important;
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.ss4};
  margin: ${({ theme }) =>
    `${theme.spacing.ss0} ${theme.spacing.ss4} ${theme.spacing.ss4}`} !important;
`;

const H1 = styled(H1Base)`
  text-align: center;
  letter-spacing: 1px;

  br {
    ${upTo("tablet")} {
      display: none;
    }
  }
`;

const H2 = styled(H2Base)`
  text-align: center;
  line-height: 25px;

  br {
    ${upTo("tablet")} {
      display: none;
    }
  }
`;

const Separator = styled.span`
  width: 320px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.misc.border.normal};
`;

const LOGO_ANIMATION_PROPS: AnimationProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration: 0.4,
  },
};

const LogoWrapper = styled(motion.div).attrs(LOGO_ANIMATION_PROPS)``;

const SubTitltWrapper = styled(motion.div).attrs({
  ...LOGO_ANIMATION_PROPS,
  transition: {
    duration: 0.4,
    delay: 0.2,
  },
})``;

const APP_DEMO_ANIMATION_PROPS: AnimationProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration: 1,
    delay: 0.4,
  },
};

const AppDemoWrapper = styled(motion.div).attrs(APP_DEMO_ANIMATION_PROPS)`
  display: flex;
  justify-content: center;
  will-change: transform, opacity;
`;

const DemoImage = styled(Image)`
  border-radius: ${({ theme }) => theme.radius.rad2};
  border: 1px solid ${({ theme }) => theme.colors.misc.border.normal};
  overflow: hidden;
`;

const Logo = styled(LogoBase)`
  margin-top: ${({ theme }) => theme.spacing.ss5};

  ${from("desktop")} {
    display: none;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.ss2};
`;

const AppLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.fs2};
`;

const DemoImageOverlay = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: ${({ theme }) => theme.colors.misc.bottomOverlay};
  pointer-events: none;
  will-change: opacity;
`;

export const Hero: FunctionComponent = () => {
  const ref = useRef<HTMLElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const y = useTransform(scrollYProgress, [0, 1], [0, 20]);
  const opacity = useTransform(scrollYProgress, [1, 0.5], [0, 1]);

  return (
    <HeroSection ref={ref}>
      <HeroWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <H1>
          Znajdź okazję
          <br /> na rynku nieruchomości
        </H1>
        <SubTitltWrapper>
          <H2>
            Homescan daje Ci dostęp do oferty największych portali nieruchomości w Polsce.
            <br /> Z nami łatwo znajdziesz ogłoszenia spełniające Twoje kryteria i oszczędzisz czas.
          </H2>
        </SubTitltWrapper>
        <Separator />
        <ActionsWrapper>
          <PrimaryLink title="Utwórz darmowe konto" href={routes.register} />
          <AppLink href={routes.app}>Przejdź do aplikacji</AppLink>
        </ActionsWrapper>
      </HeroWrapper>
      <AppDemoWrapper style={{ y, opacity }}>
        <DemoImage
          priority
          alt="Znajdź okazję na rynku nieruchomości - Homescan.pl"
          quality={100}
          src={demoScreen}
          width={800}
          height={494}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </AppDemoWrapper>
      <DemoImageOverlay style={{ opacity }} />
    </HeroSection>
  );
};
