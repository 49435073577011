import {
  add,
  differenceInHours,
  differenceInSeconds,
  endOfMonth,
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  formatISO,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMonth,
  isSameYear,
  isValid,
  isWithinInterval,
  parseISO,
  startOfMonth,
  sub,
  toDate,
} from "date-fns";
import type { Duration } from "date-fns";
import locale from "date-fns/locale/pl";

import { DATE_FORMATS } from "./constants";

type DateFormat = (typeof DATE_FORMATS)[keyof typeof DATE_FORMATS];

type ISORepresentation = "complete" | "date" | "time";

type CompareOptions = {
  /**
   * @description Inclusively the day
   */
  inclusively?: boolean;
};

type Range = {
  start: string | Date;
  end: string | Date;
};

const parseDate = (date: Date | string | number): Date => {
  if (isValid(new Date(date))) {
    const dateToPass = typeof date === "string" ? parseISO(date) : date;
    return toDate(dateToPass);
  }
  throw new Error("Passed date is not a proper string or date object.");
};

/**
 * @description DateTime is an immutable data structure representing a
 * specific date and time and accompanying methods.
 * It contains class and instance methods for creating, parsing,
 * transforming and formatting them.
 */
export class DateTime {
  private date: Date = new Date();

  /**
   * @description Helper to get the present local time.
   */
  static get DATE_FORMAT(): typeof DATE_FORMATS {
    return DATE_FORMATS;
  }

  constructor(passedDate: Date | string | number) {
    this.date = parseDate(passedDate);
  }

  /**
   * @description Create a DateTime for the current instant, in the system's time zone.
   */
  static localNow = (): DateTime => {
    const date = new Date();
    return new DateTime(date);
  };

  /**
   * @description Create a DateTime from a JavaScript Date object. Uses the default zone.
   */
  static fromNativeDate = (passedDate: Date): DateTime => {
    const date = parseDate(passedDate);
    return new DateTime(date);
  };

  /**
   * @description Create a DateTime from a ISO string. Uses the default zone.
   */
  static fromISO = (passedDate: string): DateTime => {
    const date = parseDate(passedDate);
    return new DateTime(date);
  };

  /**
   * @description Format parsed date to the defined format.
   * @param dateFormat date format
   * @returns formatted date
   */
  format = (dateFormat: DateFormat = DATE_FORMATS.default): string =>
    format(this.date, dateFormat, {
      locale,
    });

  /**
   * @description Format parsed date to ISO format.
   * @returns formatted date
   */
  formatToISO = (representation: ISORepresentation = "complete"): string =>
    formatISO(this.date, { representation });

  /**
   * @description Add the specified years, months, weeks,
   * days, hours, minutes and seconds to the parsed date.
   * @param duration the object with years, months, weeks,
   * days, hours, minutes and seconds to be added.
   */
  add = (duration: Duration): DateTime => {
    const newDate = add(this.date, duration);
    return new DateTime(newDate);
  };

  /**
   * @description Subtract the specified years, months, weeks,
   * days, hours, minutes and seconds to the parsed date.
   * @param duration the object with years, months, weeks, days,
   * hours, minutes and seconds to be added.
   */
  subtract = (duration: Duration): DateTime => {
    const newDate = sub(this.date, duration);
    return new DateTime(newDate);
  };

  /**
   * @description Checks if the parsed date is after the passed one.
   * @param dateToCompare date to compare with.
   * @param options options
   */
  isAfter = (dateToCompare: Date | string, { inclusively }: CompareOptions = {}): boolean => {
    const toCompare = parseDate(dateToCompare);

    return inclusively
      ? isEqual(this.date, toCompare) || isAfter(this.date, toCompare)
      : isAfter(this.date, toCompare);
  };

  /**
   * @description Checks if the parsed date is before the passed one.
   * @param dateToCompare date to compare with.
   * @param options options
   */
  isBefore = (dateToCompare: Date | string, { inclusively }: CompareOptions = {}): boolean => {
    const toCompare = parseDate(dateToCompare);

    return inclusively
      ? isEqual(this.date, toCompare) || isBefore(this.date, toCompare)
      : isBefore(this.date, toCompare);
  };

  /**
   * @description Check if the parsed date is same with the passed one.
   * @param dateToCompare date to compare
   */
  isSame = (dateToCompare: Date | string): boolean => {
    const toCompare = parseDate(dateToCompare);
    return isEqual(this.date, toCompare);
  };

  /**
   * @description Check if the parsed date has same year with the passed one.
   * @param dateToCompare date to compare
   */
  isSameYear = (dateToCompare: Date | string): boolean => {
    const toCompare = parseDate(dateToCompare);
    return isSameYear(this.date, toCompare);
  };

  /**
   * @description Check if the parsed date has same month (and year) with the passed one.
   * @param dateToCompare date to compare
   */
  isSameMonth = (dateToCompare: Date | string): boolean => {
    const toCompare = parseDate(dateToCompare);
    return isSameMonth(this.date, toCompare);
  };

  /**
   * @description Check if the parsed date has same day (and year and month) with the passed one.
   * @param dateToCompare date to compare
   */
  isSameDay = (dateToCompare: Date | string): boolean => {
    const toCompare = parseDate(dateToCompare);
    return isSameDay(this.date, toCompare);
  };

  /**
   * @description Checks if the parsed date is within the range.
   * @param range range to check
   */
  isWithinRange = (range: Range): boolean => {
    const start = parseDate(range.start);
    const end = parseDate(range.end);
    return isWithinInterval(this.date, { start, end });
  };

  /**
   * @description Find first day of parsed month.
   * @returns First day of parsed month
   */
  getStartOfMonth = (): Date => startOfMonth(this.date);

  /**
   * @description Find last day of parsed month.
   * @returns Last day of parsed month
   */
  getEndOfMonth = (): Date => endOfMonth(this.date);

  /**
   * @description Get the distance between the given date and now in words.
   * @returns Distance in words
   */
  getTimeDifferenceFromNow = (): string => {
    const diff = differenceInSeconds(new Date(), this.date);

    if (diff < 30) {
      return "teraz";
    }
    return formatDistanceToNow(this.date, { locale, addSuffix: true });
  };

  /**
   * @description Get the distance between the given dates in words
   * @param dateToCompare date to compare
   * @param options options for formatter
   * @returns Distance in words
   */
  getTimeDifference = (
    dateToCompare: Date | string,
    {
      unit = "day",
    }: {
      unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
    } = {},
  ): string => {
    const toCompare = parseDate(dateToCompare);
    return formatDistanceStrict(this.date, toCompare, {
      unit,
      roundingMethod: "ceil",
      locale,
      addSuffix: true,
    });
  };

  /**
   * @descriptionGet Get the number of hours between the given dates
   * @param dateToCompare date to compare
   * @returns number of hours
   */
  getDifferenceInHours = (dateToCompare: Date | string): number => {
    const toCompare = parseDate(dateToCompare);
    return differenceInHours(this.date, toCompare);
  };
}
