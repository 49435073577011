import styled from "styled-components";

import { BadgeWrapper } from "./Badge";

export const BadgeGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  ${BadgeWrapper} {
    margin-bottom: ${({ theme }) => theme.spacing.ss2};
    margin-right: ${({ theme }) => theme.spacing.ss2};
  }
`;
