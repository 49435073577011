import type { FunctionComponent, ReactNode } from "react";

import Head from "next/head";

type PageMetaProps = {
  children?: ReactNode;
  subTitle?: string;
  suffixTitle?: string;
  description?: string;
};

export const PageMeta: FunctionComponent<PageMetaProps> = ({
  subTitle,
  description = "Znajdź okazję na rynku nieruchomości",
  suffixTitle,
  children,
}) => (
  <Head>
    <title>
      {`${subTitle ? `${subTitle} - ` : ""}Homescan.pl${suffixTitle ? ` - ${suffixTitle}` : ""}`}
    </title>
    <meta name="description" content={description} />
    {children}
  </Head>
);
